import React from 'react';
import './LandingPage.css';
import logo from './images/logo.png'; // Replace with the path to your logo

function LandingPage() {
  return (
    <div className="landing-page">
      <img src={logo} alt="Logo" className="logo" />
      <h1 className="heading">SOMETHING REALLY COOL IS COMING SOON</h1>
      <div className="button-container">
        <a href="https://wa.me/+971543741565" target="_blank" className="button whatsapp-button">WhatsApp</a>
        <a href="https://www.instagram.com/iamaccolade" target="_blank" className="button instagram-button">Instagram</a>
        <a href="mailto:sales@accoladejewellers.com" className="button email-button">Email</a>
        <a href="https://www.snapchat.com/add/iamaccolade" target="_blank" className="button snapchat-button">Snapchat</a>
      </div>
      <div className="copyright">
        &copy; 2020 Accolade Jewellers. All rights reserved.
      </div>
    </div>
  );
}

export default LandingPage;
